@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';
@use '@sats-group/ui-lib/tokens/corner-radius';

.session-card {
  display: none;
  &--visible {
    display: block;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid lightmode.$ui-border;
  }

  &__inner {
    display: flex;
    background: lightmode.$background-secondary;
  }

  &__button {
    background: lightmode.$background-secondary;
    border: none;
    outline: none;

    padding: spacing.$s;
    width: 100%;
    text-align: left;

    border-left: 8px solid lightmode.$ui-graphical-elements-3;

    &--orange {
      border-left-color: lightmode.$signal-warning;
    }

    &--green {
      border-left-color: lightmode.$signal-success;
    }

    &--red {
      border-left-color: lightmode.$signal-error;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__error {
    color: lightmode.$signal-error;
    word-break: break-word;
  }

  &__options {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__options-button, &__info-modal-button {
    background: lightmode.$background-secondary;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 spacing.$s;
  }

  &__inner-top {
    display: flex;
    gap: spacing.$xs;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__time {
    color: lightmode.$on-background-secondary;
  }

  &__info-modal-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: spacing.$s;
  }

  &__info-modal, &__info-modal-inner {
    > * {
      margin-bottom: spacing.$m;
    }
  }
}
